import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";

import { PrimaryButton } from "../../elements/Button";
import I18nText from "../../elements/I18nText";
import Imprint from "../Imprint";
import PrivacyPolicy from "../PrivacyPolicy";
import WhitelabeledLoginLogo from "../Whitelabeling/WhitelabeledLoginLogo";
import { useWhitelabel, WHITELABELS } from "../../domain/whitelabel";
import LoginOrSeparator from "../../elements/Separator/LoginOrSeparator";
import LanguageSwitcher from "../../elements/LanguageSwitcher";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh"
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 400,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(4)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  loginButton: {
    marginTop: theme.spacing(2)
  },
  linkButtons: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  demoLabel: {
    marginTop: theme.spacing(2),
    fontSize: 12
  },
  registrationLabel: {
    marginTop: theme.spacing(2),
    fontSize: 12
  },
  infoLinks: {
    padding: 0,
    minWidth: "auto",
    fonWeight: "bold",
    color: "navy",
    fontSize: 10,
    textDecoration: "none",
    border: "none",
    background: "none",
    cursor: "pointer"
  }
}));

const Login = ({
  username,
  password,
  loginError,
  setUsername,
  setPassword,
  onSubmit,
  onSubmitDemo,
  showImprint,
  onToggleImprint,
  showPrivacyPolicy,
  onTogglePrivacyPolicy
}) => {
  const classes = useStyles();
  const { whitelabel } = useWhitelabel();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <WhitelabeledLoginLogo />
                <div style={{ width: "2rem" }}></div>
                <LanguageSwitcher shouldShowOutline={true} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={!!loginError}
                helperText={
                  !!loginError && (
                    <I18nText>{`login.errors.${loginError}`}</I18nText>
                  )
                }
                margin="normal"
                required
                fullWidth
                label={<I18nText>login.username</I18nText>}
                size="small"
                name="username"
                autoComplete="off"
                inputProps={{
                  autoCapitalize: "off"
                }}
                autoFocus
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                size="small"
                name="password"
                label={<I18nText>login.password</I18nText>}
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                className={classes.loginButton}
                type="submit"
                fullWidth
                onClick={onSubmit}
              >
                <I18nText>login.loginButton</I18nText>
              </PrimaryButton>
            </Grid>
            {whitelabel.name !== WHITELABELS.ACON ? (
              <>
                <Grid item xs={12}>
                  <LoginOrSeparator />
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    onClick={onSubmitDemo}
                  >
                    <I18nText>login.demoButton</I18nText>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.linkButtons}
                    fullWidth
                    variant="outlined"
                    href="https://www.tecson.de/oilview-system.html#ov-reg-form"
                    target="_blank"
                  >
                    <I18nText>login.registrationButton</I18nText>
                  </Button>
                </Grid>
              </>
            ) : (
              <div style={{ height: 150 }}></div>
            )}
            {whitelabel.name === WHITELABELS.OILVIEW ? (
              <Grid item xs={12}>
                <Box
                  marginTop={2}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography variant="caption">
                    <I18nText>login.oilInfo.information</I18nText>{" "}
                    <a
                      href="https://www.tecson.de/de/heizoelpreise.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.infoLinks}
                    >
                      <I18nText>login.oilInfo.heatingOil</I18nText>
                    </a>
                    <I18nText>login.oilInfo.oilMarketAnd</I18nText>
                    <a
                      href="https://www.tecson.de/de/oelweltmarkt.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.infoLinks}
                    >
                      <I18nText>login.oilInfo.priceDevelopment</I18nText>
                    </a>
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <div style={{ height: 10 }}></div>
            )}
            <Grid item xs={12}>
              <Box
                marginTop={2}
                display="flex"
                flexDirection="row"
                alignItems="start"
                justifyContent="space-between"
              >
                {" "}
                <Button onClick={onToggleImprint}>
                  <I18nText>login.imprint</I18nText>
                </Button>
                {whitelabel.name === WHITELABELS.OILVIEW ? (
                  <Button
                    style={{
                      minWidth: "auto",
                      fonWeight: "bold",
                      color: "navy"
                    }}
                  >
                    <I18nText>login.tecson</I18nText>
                  </Button>
                ) : (
                  <div></div>
                )}
                <Button onClick={onTogglePrivacyPolicy}>
                  <I18nText>login.privacyPolicy</I18nText>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {/*<LocaleMenu anchor={localeMenuAnchor} onClose={closeLocaleMenu} />*/}
      {showImprint && <Imprint onClose={onToggleImprint} />}
      {showPrivacyPolicy && <PrivacyPolicy onClose={onTogglePrivacyPolicy} />}
    </Container>
  );
};

export default Login;
